<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="container-fluid">
          <!-- Default box -->
          <section class="content-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="mb-0">Solicitudes Conductores</h5>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/Hse">Orion</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/Hse">Solicitud</router-link>
                    </li>
                    <li class="breadcrumb-item active">Conductores</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="container-fluid">
              <div class="card">
                <div class="card-header pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn bg-navy"
                          @click="getIndex()"
                        >
                          <i class="fas fa-sync-alt"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-md bg-danger"
                          @click="back()"
                        >
                          <i class="fas fa-broom"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          v-if="
                            $store.getters.can(
                              'funcionariofrontera.solicitudConductor.create'
                            )
                          "
                          @click="create()"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row justify-content-center">
                    <div class="form-group col-md-1">
                      <label>Id</label>
                      <input
                        type="number"
                        class="form-control form-control-sm p-0"
                        v-model="filters.solicitud_id"
                        label="id"
                        @keyup="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Tipo Documento</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="tipo_documento"
                        placeholder="Tipo Documento"
                        label="descripcion"
                        :options="listsForms.tipo_documentos"
                        @input="selectTipoDocumento()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Número de Documento</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="filters.documento"
                        @keyup="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label>Conductor</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filters.nombre"
                        @keyup="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filters.email"
                        @keyup="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-5">
                      <label>Empresa</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="empresa"
                        placeholder="Empresa"
                        label="descripcion"
                        :options="listsForms.empresas"
                        @input="selectEmpresa()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Clase Vehículo</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="clase_vehiculo"
                        placeholder="Clase Vehículo"
                        label="descripcion"
                        :options="listsForms.clases_vehiculos"
                        @input="selectClaseVehiculo()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-3">
                      <label>Activo</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="activo"
                        placeholder="Activo"
                        label="descripcion"
                        :options="listsForms.activos"
                        @input="selectActivo()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Tipo Contrato</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="tipo_contrato"
                        placeholder="Tipo Contrato"
                        label="descripcion"
                        :options="listsForms.tipos_contratos"
                        @input="selectTipoContrato()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filters.estado"
                        @change="getIndex()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estadoOpc in listsForms.estados"
                          :key="estadoOpc.numeracion"
                          :value="estadoOpc.numeracion"
                        >
                          {{ estadoOpc.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table
                    style="font-size: 0.85em"
                    class="table table-bordered table-striped table-hover table-sm text-nowrap"
                  >
                    <thead class="text-center bg-dark">
                      <tr>
                        <th>Id</th>
                        <th>Conductor</th>
                        <th>Foto</th>
                        <th>Email</th>
                        <th>Tipo Documento</th>
                        <th>Número de Documento</th>
                        <th>Empresa</th>
                        <th class="col-2">Clase Vehículo</th>
                        <th>Activo</th>
                        <th>Tipo Contrato</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="solicitud in solicitudesConductores.data"
                        :key="solicitud.data"
                      >
                        <td class="text-center">
                          <button
                            type="button"
                            class="btn btn-sm btn-default py-0"
                            data-toggle="modal"
                            data-target="#modal-form-detalles"
                            @click="modalDetalles(solicitud)"
                          >
                            {{ solicitud.id }}
                          </button>
                        </td>
                        <td>
                          {{ solicitud.Nombres }}
                        </td>
                        <td>
                          <a
                            v-if="
                              solicitud.link_foto ? solicitud.link_foto : null
                            "
                            :href="uri_docs + solicitud.link_foto"
                            target="_blank"
                          >
                            <img
                              :src="uri_docs + solicitud.link_foto"
                              alt="Conductor"
                              width="75"
                              class="rounded mx-auto d-block"
                            />
                          </a>
                        </td>
                        <td>{{ solicitud.email }}</td>
                        <td class="text-center">
                          {{ solicitud.TipoDocumento }}
                        </td>
                        <td class="text-center">
                          {{ solicitud.numero_documento }}
                        </td>
                        <td>{{ solicitud.Empresa }}</td>
                        <td class="text-center">
                          {{ solicitud.ClaseVh }}
                        </td>
                        <td>{{ solicitud.Activo }}</td>
                        <td>{{ solicitud.TipoContrato }}</td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="[
                              solicitud.estado == 1
                                ? 'badge-secondary'
                                : solicitud.estado == 2
                                ? 'badge-success'
                                : solicitud.estado == 3
                                ? 'badge-danger'
                                : solicitud.estado == 4
                                ? 'bg-olive'
                                : solicitud.estado == 5
                                ? 'badge-warning'
                                : solicitud.estado == 6
                                ? 'badge-info'
                                : solicitud.estado == 7
                                ? 'bg-navy'
                                : solicitud.estado == 8
                                ? 'bg-lime'
                                : '',
                            ]"
                            >{{ solicitud.Estado }}</span
                          >
                        </td>
                        <td>
                          <div class="btn-group float-right">
                            <button
                              v-if="
                                (solicitud.estado == 2 ||
                                  solicitud.estado == 4) &&
                                  $store.getters.can(
                                    'funcionariofrontera.solicitudConductor.verPaseCampo'
                                  )
                              "
                              type="button"
                              class="btn btn-sm bg-success"
                              @click="
                                $refs.PaseCampoModal.getSolicitud(solicitud)
                              "
                              data-toggle="modal"
                              data-target="#modaL_pase"
                            >
                              <i class="fas fa-wallet"></i>
                            </button>

                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              @click="edit(solicitud)"
                              v-if="
                                $store.getters.can(
                                  'funcionariofrontera.solicitudConductor.edit'
                                )
                              "
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="solicitudesConductores.total">
                    Mostrando del <b>{{ solicitudesConductores.from }}</b> al
                    <b>{{ solicitudesConductores.to }}</b> de un total:
                    <b>{{ solicitudesConductores.total }}</b> Registros
                  </div>
                  <div class="float-left" v-else>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </div>
                  <pagination
                    :data="solicitudesConductores"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    class="float-right m-0"
                  ></pagination>
                </div>
              </div>
            </div>
            <PaseCampoModal ref="PaseCampoModal" />
            <!-- <SolicitudesConductoresExport ref="SolicitudesConductoresExport" /> -->
          </section>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="modal-form-detalles">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <div class="row w-100">
                <div class="col-md-10">
                  <h4 class="modal-title text-white">
                    Solicitud Conductor - {{ modalDetalle.id }}
                  </h4>
                </div>
                <div class="col-md-2">
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="closeModal"
                    id="close-modal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-body p-0 pt-1">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Documentos"
                    data-toggle="tab"
                    href="#Documentos"
                    v-if="modalDetalle.id"
                    >Documentos</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-EstadosHistoricos"
                    data-toggle="tab"
                    href="#EstadosHistoricos"
                    v-if="modalDetalle.id"
                    >Histórico Estados</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="card card-info card-outline collapsed-card m-0">
                      <div class="card-header pt-2 pb-2">
                        <h5 class="card-title">
                          Datos Solicitud Conductor
                        </h5>
                        <div class="card-tools">
                          <button
                            type="button"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                            class="btn btn-tool"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <p class="col-sm-6 text-bold">Tipo Documento:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.TipoDocumento }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">
                            Número Documento:
                          </p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.numero_documento }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Nombres:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.nombres }}
                          </p>
                        </div>

                        <div class="row">
                          <p class="col-sm-6 text-bold">Apellidos:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.apellidos }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Email:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.email }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">
                            Fecha Nacimiento:
                          </p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.fecha_nacimiento }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Empresa:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.Empresa }}
                          </p>
                        </div>

                        <div class="row">
                          <p class="col-sm-6 text-bold">Activo:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.Activo }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Tipo Contrato:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.TipoContrato }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">
                            Tipo Categoria Licencia:
                          </p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.CategoriaLic }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Clase Vehículo:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.ClaseVh }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Observaciones:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.observaciones }}
                          </p>
                        </div>
                        <div class="row">
                          <p class="col-sm-6 text-bold">Estado:</p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.Estado }}
                          </p>
                        </div>
                        <div
                          class="row"
                          v-if="modalDetalle.observaciones_estado"
                        >
                          <p class="col-sm-6 text-bold">
                            Observaciones Estado:
                          </p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.observaciones_estado }}
                          </p>
                        </div>
                        <div
                          class="row"
                          v-if="modalDetalle.fecha_fin_pase_campo"
                        >
                          <p class="col-sm-6 text-bold">
                            Fecha Fin Pase Campo:
                          </p>
                          <p class="col-sm-6 description-header">
                            {{ modalDetalle.fecha_fin_pase_campo }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Documentos  -->
                <div
                  class="tab-pane fade"
                  id="Documentos"
                  v-if="modalDetalle.id"
                >
                  <SolicitudConductorDocumento
                    v-if="modalDetalle.id != null"
                    ref="SolicitudConductorDocumento"
                  />
                </div>
                <!-- Estados Historicos  -->
                <div
                  class="tab-pane fade"
                  id="EstadosHistoricos"
                  v-if="modalDetalle.id"
                >
                  <SolicitudConductorHistoricoEstado
                    v-if="modalDetalle.id != null"
                    ref="SolicitudConductorHistoricoEstado"
                  />
                </div>
              </div>
              <!-- <div class="modal-footer justify-content-between">
                <button
                  
                  type="button"
                  class="btn btn-primary"
                >
                  Guardar
                </button>
              </div> -->
            </div>
            <!-- /.modal-content -->
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import SolicitudConductorDocumento from "./SolicitudConductorDocumento";
import PaseCampoModal from "./PaseCampoModal.vue";
import SolicitudConductorHistoricoEstado from "./SolicitudConductorHistoricoEstado";
//import SolicitudesConductoresExport from "../solicitudes/SolicitudesConductoresExport";
export default {
  name: "SolicitudesConductoresIndex",
  components: {
    vSelect,
    pagination,
    Loading,
    SolicitudConductorDocumento,
    PaseCampoModal,
    SolicitudConductorHistoricoEstado,
  },
  data() {
    return {
      cargando: false,
      modalDetalle: [],
      filters: {
        solicitud_id: null,
        nombre: null,
        email: null,
        tipo_documento_id: null,
        tipo_contrato_id: null,
        documento: null,
        empresa_id: null,
        activo_id: null,
        estado: null,
        clase_vehiculo_id: null,
        new: true,
      },
      tipo_documento: null,
      empresa: null,
      activo: null,
      tipo_contrato: null,
      clase_vehiculo: null,
      solicitudesConductores: {},
      listsForms: {
        empresas: [],
        activos: [],
        tipos_contratos: [],
        clases_vehiculos: [],
        tipo_documentos: [],
        estados: [],
      },
      form: {
        id: null,
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      axios
        .get(
          "api/funcionariosFrontera/HseSolicitudesConductores?page=" + page,
          {
            params: await this.filters,
          }
        )
        .then((response) => {
          this.cargando = false;
          this.solicitudesConductores = response.data;
        });
    },

    back() {
      this.clearFilters();
      this.getIndex();
    },

    clearFilters() {
      this.filters = {
        solicitud_id: null,
        nombre: null,
        email: null,
        tipo_documento_id: null,
        tipo_contrato_id: null,
        documento: null,
        empresa_id: null,
        activo_id: null,
        estado: null,
        clase_vehiculo_id: null,
        new: true,
      };
      this.tipo_documento = null;
      this.empresa = null;
      this.activo = null;
      this.tipo_contrato = null;
      this.clase_vehiculo = null;
    },

    getEmpresas() {
      axios.get("/api/lista/164").then((response) => {
        this.listsForms.empresas = response.data;
      });
    },

    getActivos() {
      axios.get("/api/lista/117").then((response) => {
        this.listsForms.activos = response.data;
      });
    },

    getTiposContratos() {
      axios.get("/api/lista/26").then((response) => {
        this.listsForms.tipos_contratos = response.data;
      });
    },

    getClasesVehiculos() {
      axios.get("/api/lista/163").then((response) => {
        this.listsForms.clases_vehiculos = response.data;
      });
    },

    getTipoDocumentos() {
      axios.get("/api/lista/6").then((response) => {
        this.listsForms.tipo_documentos = response.data;
      });
    },
    getEstados() {
      axios.get("/api/lista/175").then((response) => {
        this.listsForms.estados = response.data;
      });
    },

    //Seleccionar solo ids
    selectEmpresa() {
      this.filters.empresa_id = null;
      if (this.empresa) {
        this.filters.empresa_id = this.empresa.numeracion;
      }
      this.getIndex();
    },

    selectActivo() {
      this.filters.activo_id = null;
      if (this.activo) {
        this.filters.activo_id = this.activo.numeracion;
      }
      this.getIndex();
    },

    selectTipoContrato() {
      this.filters.tipo_contrato_id = null;
      if (this.tipo_contrato) {
        this.filters.tipo_contrato_id = this.tipo_contrato.numeracion;
      }
      this.getIndex();
    },

    selectTipoDocumento() {
      this.filters.tipo_documento_id = null;
      if (this.tipo_documento) {
        this.filters.tipo_documento_id = this.tipo_documento.numeracion;
      }
      this.getIndex();
    },

    selectClaseVehiculo() {
      this.filters.clase_vehiculo_id = null;
      if (this.clase_vehiculo) {
        this.filters.clase_vehiculo_id = this.clase_vehiculo.numeracion;
      }
      this.getIndex();
    },

    edit(solicitud) {
      return this.$router.push({
        name: "/FuncionariosFrontera/SolicitudesConductoresForm",
        params: {
          accion: "Editar",
          data_edit: solicitud,
          id: solicitud.id,
        },
      });
    },
    create() {
      return this.$router.push({
        name: "/FuncionariosFrontera/SolicitudesConductoresForm",
      });
    },
    modalDetalles(solicitud) {
      this.form.id = null;
      this.modalDetalle = [];
      this.modalDetalle = solicitud;
      this.form.id = solicitud.id;
      if (this.$refs.SolicitudConductorDocumento) {
        this.$refs.SolicitudConductorDocumento.getSolicitudConductor(
          this.form.id
        );
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEmpresas();
    this.getActivos();
    this.getTiposContratos();
    this.getClasesVehiculos();
    this.getTipoDocumentos();
    this.getEstados();
  },
};
</script>
