<template>
  <div>
    <div class="card-body">
      <div
        v-for="paquete in solicitudConductor.paquetes"
        :key="paquete.id"
        class="card card-warning card-outline m-0"
      >
        <div class="card-header pt-2 pb-2">
          <h3 class="card-title">
            <i class="far fa-folder"></i>
            {{ paquete.nombre }}
          </h3>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
              data-toggle="tooltip"
              title="Collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body p-0" style="display: block">
          <table
            id="docs"
            class="table table-bordered table-hover table-sm text-center m-0"
          >
            <thead class="bg-light">
              <tr>
                <th>Documento</th>
                <th>Referencia</th>
                <th>Emisor</th>
                <th>Fecha Emisión</th>
                <th>Fecha Vencimiento</th>
                <th colspan="3" v-if="!$parent.modalDetalle.id">
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="documento in paquete.documentos"
                :key="documento.id"
                v-bind:class="
                  documento.pivot.relevante &&
                  !validaDoc(solicitudConductor.documentos_files, documento, 2)
                    ? 'alert-default-danger'
                    : 'alert-default-success'
                "
              >
                <td v-text="documento.descripcion"></td>
                <td>
                  <p
                    v-if="
                      validaDoc(
                        solicitudConductor.documentos_files,
                        documento,
                        1
                      )
                    "
                  >
                    {{
                      solicitudConductor.documentos_files.find(
                        (file) => file.documento_id === documento.id
                      ).referencia
                    }}
                  </p>
                </td>
                <td>
                  <p
                    v-if="
                      validaDoc(
                        solicitudConductor.documentos_files,
                        documento,
                        1
                      )
                    "
                  >
                    {{
                      solicitudConductor.documentos_files.find(
                        (file) => file.documento_id === documento.id
                      ).emisor
                    }}
                  </p>
                </td>
                <td>
                  <p
                    v-if="
                      validaDoc(
                        solicitudConductor.documentos_files,
                        documento,
                        1
                      )
                    "
                  >
                    {{
                      solicitudConductor.documentos_files.find(
                        (file) => file.documento_id === documento.id
                      ).fecha_emision
                    }}
                  </p>
                </td>
                <td>
                  <p
                    v-if="
                      validaDoc(
                        solicitudConductor.documentos_files,
                        documento,
                        1
                      )
                    "
                  >
                    {{
                      solicitudConductor.documentos_files.find(
                        (file) => file.documento_id === documento.id
                      ).fecha_vencimiento
                    }}
                  </p>
                </td>
                <td v-if="!$parent.modalDetalle.id">
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    :disabled="
                      validaDoc(
                        solicitudConductor.documentos_files,
                        documento,
                        2 || $parent.bloquear
                      )
                    "
                    data-toggle="modal"
                    data-target="#Modal_docs"
                    @click="abrirModalDoc('Agregar', documento)"
                  >
                    <i class="fa fa-upload"></i>
                  </button>
                </td>

                <td v-if="!$parent.modalDetalle.id">
                  <a
                    target="_blank"
                    class="btn btn-primary btn-sm"
                    :href="
                      uri_docs +
                        solicitudConductor.documentos_files.find(
                          (file) => file.documento_id === documento.id
                        ).link
                    "
                    v-if="
                      validaDoc(
                        solicitudConductor.documentos_files,
                        documento,
                        2
                      )
                    "
                  >
                    <i class="fa fa-download"></i>
                  </a>
                </td>
                <td v-if="!$parent.modalDetalle.id">
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    :disabled="$parent.bloquear"
                    v-if="
                      validaDoc(
                        solicitudConductor.documentos_files,
                        documento,
                        1
                      )
                    "
                    @click="
                      eliminarDoc(
                        solicitudConductor.documentos_files.find(
                          (file) => file.documento_id === documento.id
                        ).id
                      )
                    "
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- MODAL DOCUMENTOS -->
    <div class="modal fade" id="Modal_docs">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="descripcion">Descripción</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="descripcion"
                    placeholder="descripcion"
                    v-model="formDoc.descripcion"
                    :class="
                      $v.formDoc.descripcion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="referencia">Referencia</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="referencia"
                    placeholder="Referencia"
                    v-model="formDoc.referencia"
                    :class="
                      $v.formDoc.referencia.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="emisor">Emisor</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="emisor"
                    placeholder="Emisor"
                    v-model="formDoc.emisor"
                    :class="
                      $v.formDoc.emisor.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="fecha_emision">Fecha Emisión</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="fecha_emision"
                    placeholder="Fecha Emisión"
                    v-model="formDoc.fecha_emision"
                    :class="
                      $v.formDoc.fecha_emision.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="validaFechas"
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-md-6 col-sm-12"
                v-if="formDoc.vencimiento"
              >
                <div class="form-group">
                  <label for="fecha_vencimiento">Fecha Vencimiento</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="fecha_vencimiento"
                    placeholder="Fecha Emisión"
                    v-model="formDoc.fecha_vencimiento"
                    :class="
                      $v.formDoc.fecha_vencimiento.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="validaFechas"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <input
                  type="file"
                  class="form-control-file"
                  ref="file"
                  id="btnFile"
                  @change="getFile"
                  accept="application/msword,application/pdf,application/rtf,image/*"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-primary" @click="saveDoc()">
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "SolicitudConductorDocumento",
  data() {
    return {
      negar: null,
      cargando: false,
      solicitudConductor: [],
      formDoc: {},
      formPaquete: {},
      conductorPaquetes: [],
      btnVerificado: {},
      estadoDocs: true,
      modal: {
        title: "",
        accion: "",
      },
      fileDoc: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    if (this.formDoc.vencimiento) {
      return {
        formDoc: {
          descripcion: {
            required,
          },
          referencia: {
            required,
          },
          emisor: {
            required,
          },
          fecha_emision: {
            required,
          },
          fecha_vencimiento: {
            required,
          },
        },
        formPaquete: {
          paquete_id: {
            required,
          },
        },
      };
    } else {
      return {
        formDoc: {
          descripcion: {
            required,
          },
          referencia: {
            required,
          },
          emisor: {
            required,
          },
          fecha_emision: {
            required,
          },
        },
        formPaquete: {
          paquete_id: {
            required,
          },
        },
      };
    }
  },
  methods: {
    getSelectPaquete() {
      this.formPaquete.paquete_id = this.formPaquete.listPaquetes.id;
    },
    async getSolicitudConductor(id) {
      if (id) {
        await axios
          .get("/api/funcionariosFrontera/HseSolicitudesConductores/" + id)
          .then(async (response) => {
            this.solicitudConductor = response.data;
            await this.getVerificado();
            this.$parent.solicita = await this.validarSolicitar();
            if (this.$parent.solicita && this.$parent.form.estado == 1) {
              this.$swal({
                icon: "warning",
                title: `Para solicitar oprima el botón Solicitar.`,
                text:
                  "el botón Solicitar se encuentra en la parte inferior de color: AMARILLO.",
                confirmButtonText: "Ok",
                showCancelButton: false,
              });
            }
          });
      }
    },

    async validarSolicitar() {
      let paquete = this.solicitudConductor.paquetes;
      let files = this.solicitudConductor.documentos_files;
      this.estadoDocs = true;
      let fileDoc = null;
      if (paquete.length > 0) {
        for (let i = 0; i < paquete.length; i++) {
          const element = paquete[i];
          for (let j = 0; j < element.documentos.length; j++) {
            const doc = element.documentos[j];
            fileDoc = await files.find((file) => file.documento_id === doc.id);
            if (doc.pivot.relevante == 1) {
              if (fileDoc === undefined) {
                this.estadoDocs = false;
              }
            }
          }
        }
      } else {
        this.estadoDocs = false;
      }
      return this.estadoDocs;
    },

    abrirModalDoc(accion, documento) {
      this.modal.accion = accion;
      this.modal.title = accion + " Documento";
      this.formDoc = {
        entidad_id: this.$parent.form.id,
        documento_id: documento.id,
        descripcion: documento.descripcion,
        vencimiento: documento.vencimiento,
      };
    },

    getFile(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.fileDoc = file;
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        let inputImage = document.getElementById("btnFile");
        inputImage.value = "";
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    validaDoc(files, documento, tipo) {
      if (
        files.find((file) => file.documento_id === documento.id) !== undefined
      ) {
        if (tipo == 2) {
          if (
            files.find((file) => file.documento_id === documento.id).link !== ""
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    validarFechasSolicitar() {
      let expired_files = [];
      const fecha_actual = new Date();
      this.solicitudConductor.paquetes.forEach((paquete) => {
        paquete.documentos.forEach((documento) => {
          if (
            this.solicitudConductor.documentos_files.find(
              (file) => file.documento_id === documento.id
            ) !== undefined
          ) {
            let file = this.solicitudConductor.documentos_files.find(
              (file) => file.documento_id === documento.id
            );
            file.descripcion = documento.descripcion;
            if (documento.vencimiento == 1) {
              if (fecha_actual >= new Date(file.fecha_vencimiento)) {
                expired_files.push(file.descripcion);
              }
            }
          }
        });
      });
      return expired_files;
    },

    validaFechas() {
      if (this.formDoc.fecha_emision && this.formDoc.fecha_vencimiento) {
        const fecha_menor = new Date(this.formDoc.fecha_emision);
        const fecha_mayor = new Date(this.formDoc.fecha_vencimiento);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.formDoc.fecha_vencimiento = null;
          this.$swal({
            icon: "error",
            title: `La fecha de emisión no puede ser mayor a la fecha de vencimiento...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    async saveDoc() {
      if (!this.$v.formDoc.$invalid) {
        this.$parent.cargando = true;
        let formData = new FormData();
        formData.append("entidad_id", this.formDoc.entidad_id);
        formData.append("entidad", "HseSolicitudConductor");
        formData.append("documento_id", this.formDoc.documento_id);
        formData.append("descripcion", this.formDoc.descripcion);

        formData.append("referencia", this.formDoc.referencia);
        formData.append("emisor", this.formDoc.emisor);
        formData.append("fecha_emision", this.formDoc.fecha_emision);
        formData.append("fecha_vencimiento", this.formDoc.fecha_vencimiento);
        formData.append("file", this.fileDoc);

        await axios
          .post(
            "/api/funcionariosFrontera/HseSolicitudesConductores/documentos",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async (res) => {
            if (res.data.msg) {
              this.$swal({
                icon: "error",
                title: res.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            } else {
              this.$refs.closeModal.click();
              this.formDoc = {};
              this.fileDoc = null;
              document.getElementById("btnFile").value = "";
              this.$swal({
                icon: "success",
                title: "Se guardo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              await this.getSolicitudConductor(this.$parent.form.id);
            }
            this.$parent.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Ocurrio un error, vuelva a intentarlo...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    eliminarDoc(idDoc) {
      this.$swal({
        title: "Esta seguro de eliminar este Documento?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then(async (result) => {
        this.$parent.cargando = true;
        if (result.value) {
          let datos = {
            entidad_id: this.$parent.form.id,
            entidad: "HseSolicitudConductor",
          };
          await axios({
            method: "DELETE",
            url:
              "/api/funcionariosFrontera/HseSolicitudesConductores/documentos/" +
              idDoc,
            data: datos,
          }).then(async () => {
            await this.$parent.changeEstado(
              "Actualización por modificación de documento"
            );
            await this.getSolicitudConductor(this.$parent.form.id);
            this.$swal({
              icon: "success",
              title: "Se elimino la Documento exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
        this.$parent.cargando = false;
      });
    },

    async getVerificado() {
      for (let i = 0; i < this.solicitudConductor.paquetes.length; i++) {
        const paquete = this.solicitudConductor.paquetes[i];
        for (let j = 0; j < paquete.documentos.length; j++) {
          const documento = paquete.documentos[j];
          let verif = await this.solicitudConductor.documentos_files.find(
            (file) => file.documento_id === documento.id
          );

          if (verif !== undefined) {
            this.btnVerificado[
              paquete.id + "-" + documento.id
            ] = await this.solicitudConductor.documentos_files.find(
              (file) => file.documento_id === documento.id
            ).verificado;
          } else {
            this.btnVerificado[paquete.id + "-" + documento.id] = false;
          }
        }
      }
    },
  },
  async mounted() {
    if (this.$parent.form) {
      await this.getSolicitudConductor(this.$parent.form.id);
    }
  },
};
</script>
